import FormModal from '@/components/FormModal'
import ConfirmModal from '@/components/ConfirmModal'
import {
  getPatientInfo,
  getStatusLogList,
  getTcuList,
  getDestinationList,
  getBedList,
  getNotesList,
  updatePatientStatus,
  postTcuAction
} from '@/services'
import {
  FLIC_PATIENT_STATUS_LIST,
  FLIC_STATUS_CHANGE_FORM_ITEMS
} from '@/utils/constant'
import utils from '@/utils/Utils'
export const patientInfoMixin = {
  data () {
    return {
      statusLogList: [],
      tcuList: [],
      patient: null,
      dialogVisible: false,
      dialogFormDataGroups: [],
      dialogTitle: '',
      confirmDialogVisible: false,
      selectedAction: '',
      form: null,
      comfirmHtml: '',
      confirmInfoItems: [],
      dialogModifyData: {},
      isReviewNotes: false,
      confirmDialogTitle: 'Confirm your update',
      labelWidth: '180px'
    }
  },
  components: {
    FormModal,
    ConfirmModal
  },
  beforeCreate () {
    FLIC_STATUS_CHANGE_FORM_ITEMS[2].formItems[1].object.apifuncobj = {
      // apifunc: () => getUserList({role_eq: 'doctor'}, false),
      // type: 'users'
      apifunc: () => getDestinationList({}, false),
      type: 'destinations'
    }
    FLIC_STATUS_CHANGE_FORM_ITEMS[4].formItems[0].object.apifuncobj = {
      apifunc: () => getBedList({active_eq: true}, false),
      type: 'beds'
    }
  },
  methods: {
    async actionChangeHandler(item) {
      if (!this.patient) return
      if (this.patient.status == 'rejected') {
        this.$message.error('The patient is Rejected')
        return
      }
      if (item.type == 'modal') {
        if (!item.formItems) {
          this.reviewNotesHandler(item)
          return
        }
        this.dialogFormDataGroups = [
          {
            title: '',
            name: item.dialogType,
            formDataItems: JSON.parse(JSON.stringify(item.formItems))
          }
        ]
        this.$set(this, 'confirmInfoItems', [])
        this.selectedAction = item
        this.dialogTitle = item.label
        this.dialogVisible = true
        this.labelWidth = item.dialogType =='updateMinimumReviewFrequency' ? '250px' : '180px'
        // if (item.dialogType == 'updateBedNumber') {
        //   this.initSelectList({
        //     apifunc: () => getBedList({}, false),
        //     type: 'beds',
        //     fidx: 0,
        //     idx: 0
        //   }, this.dialogFormDataGroups)
        // }
        if (item.getDataFuncs && item.getDataFuncs.length > 0) {
          item.getDataFuncs.forEach((d) => {
            this.initSelectList(d, this.dialogFormDataGroups)
          })
        }
        if (item.dialogType == 'updateStatus'|| item.dialogType == 'addTcu') {
          this.dialogModifyData = {}
        } else {
          if (this.patient && utils.isPlainObject(this.patient[item.datakey])) {
            this.patient[item.datakey + '_id'] = this.patient[item.datakey].id
          }
          this.dialogModifyData = this.patient
        }
      } else if (item.type == 'jump') {
        this.$router.push(item.url + '?id=' + this.patient.id)
      }
    },
    reviewNotesHandler (item) {
      this.isReviewNotes = true
      this.confirmDialogVisible = true
      this.selectedAction = item
      this.confirmDialogTitle = 'Review'
    },
    selectchangeHandler(val, item, index, fidx, pageType) {
      const list = FLIC_STATUS_CHANGE_FORM_ITEMS.filter((d) => d.list.includes(val))
      let apiList = []
      let dialogFormItems = JSON.parse(JSON.stringify(this.dialogFormDataGroups))
      dialogFormItems[0].name = val
      if (pageType == 'index') {
        dialogFormItems[index].formDataItems = JSON.parse(JSON.stringify(this.actions[0].formItems))
      } else if (pageType == 'details') {
        dialogFormItems[index].formDataItems = JSON.parse(JSON.stringify(this.updateStatusActions[0].formItems))
      }
      dialogFormItems[0].formDataItems[0].value = val
      if (list.length > 0 && list[0].formItems.length > 0) {
        let { formItems } = list[0]
        dialogFormItems[index].formDataItems.splice(fidx + 1, 0, ...formItems)
      }
      dialogFormItems.forEach((d, d_i) => {
        if (d.formDataItems && d.formDataItems.length > 0) {
          d.formDataItems.forEach((f, f_i) => {
            if (f.object.apifuncobj) {
              apiList.push({
                ...f.object.apifuncobj,
                fidx: d_i,
                idx: f_i
              })
            }
          })
        }
      })
      apiList.forEach(async (a) => {
        this.initSelectList(a, dialogFormItems)
      })
      this.$set(this, 'dialogFormDataGroups', dialogFormItems)
    },
    async initSelectList (a, formItems) {
      formItems[a.fidx].formDataItems[a.idx].object.loading = true
      try {
        const res = await a.apifunc()
        if (res.data[a.type]) {
          this[a.type + 'List'] = res.data[a.type]
          formItems[a.fidx].formDataItems[a.idx].options = res.data[a.type]
        }
      } catch (error) {}
      formItems[a.fidx].formDataItems[a.idx].object.loading = false
      this.$set(this, 'dialogFormDataGroups', formItems)
    },
    async dialogSubmitHandler (form) {
      this.form = form
      this.$set(this, 'form', form)
      this.countConfirmHtml(form)
      this.confirmDialogVisible = true
      this.isReviewNotes = false
      this.confirmDialogTitle = 'Confirm your update'
    },
    countConfirmHtml (form) {
      const { dialogType, dialogText } = this.selectedAction
      let str = ` <div><span style="padding-left:5px;">${dialogText} <span>`
      switch (dialogType) {
        case 'updateStatus':
          const s1 = FLIC_PATIENT_STATUS_LIST.filter((d) => d.value == this.patient.status )
          const s2 = FLIC_PATIENT_STATUS_LIST.filter((d) => d.value == form.status )
          str += ` from <span class="textdarkblue textmedium"> ${s1.length > 0 ? s1[0].name : '-'} </span> to 
            <span class="textdarkblue textmedium">${s2.length > 0 ? s2[0].name : '-'}</span> for the patient below:</div>`
        break;
        case 'addTcu':
          const formItems = this.selectedAction.formItems
          let confirmInfoItems = []
          if (formItems) {
            formItems.forEach((d) => {
              confirmInfoItems.push({
                label: d.label,
                prop: d.prop
              })
            })
          }
          this.$set(this, 'form', form)
          this.$set(this, 'confirmInfoItems', confirmInfoItems)
          str += 'for the patient below:</div>'
        break;
        default:
          const { datakey } = this.selectedAction
          let name = 'none'
          if (this.patient[datakey]) {
            name = utils.isPlainObject(this.patient[datakey])
          ? this.patient[datakey].name : this.patient[datakey]
          }
          let name1 = form[datakey]
          if (datakey == 'bed' && this.bedsList && this.bedsList.length > 0) {
            const idx = this.bedsList.findIndex((d) => d.id == form['bed_id'])
            if (idx >= 0) name1 = this.bedsList[idx].name
          } else if (datakey == 'doctor' && this.usersList && this.usersList.length > 0) {
            const idx = this.usersList.findIndex((d) => d.id == form['doctor_id'])
            if (idx >= 0) name1 = this.usersList[idx].name
          }
          str += `from <span class="textdarkblue textmedium">${name}</span> to 
           <span class="textdarkblue textmedium">${name1}</span> for the patient below:</div>`
        break; 
      }
      this.comfirmHtml = str
    },
    async postSubmitHandler () {
      const { form, patient, selectedAction } = this
      let data = {}
      if (selectedAction.dataObjKey) {
        data[selectedAction.dataObjKey] = {
          ...form
        }
      } else {
        data = { ...form }
      }
      const url = selectedAction.dialogType == 'updateStatus' ? form.status : selectedAction.id
      let api = () => updatePatientStatus(patient.id, url, data, selectedAction.action)
      if (selectedAction.dialogType.indexOf('add') >= 0) {
        data[selectedAction.dataObjKey].patient_id = patient.id
        api = () => postTcuAction(selectedAction.action, selectedAction.url, data)
        //api = () => createTcu(data)
      }
      try {
        const res = await api()
        if (res.code >= 200 && res.code < 300) {
          this.confirmDialogVisible = false
          this.dialogVisible = false
          utils.requestSuccessCb(this, 'Update Success', 500, () => {
            this.initData(patient.id, patient.ic, 'refresh')
          })
        }
      } catch (error) {}
    },
    async initPatientInfo (id, type) {
      try {
        const res = await getPatientInfo(id)
        if (res.data.patient) {
          const { patient } = res.data
          if (patient.status) {
            patient.status_name = utils.getComputedStatusName(FLIC_PATIENT_STATUS_LIST, patient.status)
          }
          patient.ic_str = patient.ic ? utils.encryptedStr(patient.ic) : '-'
          this.patient = patient
          if (type == 'refresh') {
            if (this.$refs.patientTable) this.$refs.patientTable.changeStatus(patient.id, patient)
          }
        }
      } catch (error) {
        
      }
    },
    async initStatusLogList (id) {
      try {
        const res = await getStatusLogList(id, false)
        if (res.data.statuses) {
          this.statusLogList = res.data.statuses
        }
      } catch (error) {
        
      }
    },
    async initNotesList (id) {
      try {
        const res = await getNotesList({
          patient_id_eq: id
        }, false)
        if (res.data.notes) {
          this.notesList = res.data.notes
        }
      } catch (error) {
        
      }
    },
    async initTcuList (id, ic) {
      try {
        const res = await getTcuList({
          patient_ic_cont: ic,
          patient_id_eq: id,
          page: 1,
          per_page: 5
        }, false)
        if (res.data.tcus) {
          this.tcuList = res.data.tcus
        }
      } catch (error) {
        
      }
    }
  }
}
<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="60%"
    :append-to-body="true"
    :before-close="() => $emit('close', false)"
  >
    <CommonForm
      :key="formKey"
      ref="dialogForm"
      label-position="right"
      :label-width="labelWidth"
      :formDataGroups="formDataGroups"
      :modifyData="dialogModifyData"
      :cancelFunc="() => $emit('close', false)"
      confirmTxt="Next"
      v-on="$listeners"
    />
  </el-dialog>
</template>

<script>
import CommonForm from '@/components/CommonForm'
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    formDataGroups: {
      type: Array,
      default: () => []
    },
    dialogModifyData: {
      type: Object,
      default: () => {}
    },
    labelWidth: {
      type: String,
      default: '180px'
    }
  },
  components: {
    CommonForm
  },
  watch: {
    formDataGroups: {
      handler (list) {
        if (list && list.length > 0) {
          this.formKey = 'dialogForm' + '-' + list[0].name
          this.$forceUpdate()
        }
      },
      deep: true
    }
  },
  data () {
    return {
      formKey: 'dialogForm'
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .commonform {
    display: flex;
    height: auto;
  }
  .el-dialog__header {
    padding-bottom: 20px;
    border-bottom: $border-style;
  }

  .el-form {
    width: 100%;
  }
} 
</style>
<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="60%"
    :append-to-body="true"
    :before-close="() => $emit('close', false)"
  >
    <div class="confirm-modal_content">
      <div v-if="!isReviewNotes" class="d-flex confirm-modal_txt">
        <div>Please confirm that you will be</div>
        <div v-html="comfirmHtml"></div>
      </div>
      <div v-if="isReviewNotes" class="d-flex">
        <el-checkbox v-model="isReviewed">I have reviewed the patient below:</el-checkbox>
      </div>
      <div class="mt15 confirm-modal_user">
        <div>{{ username }}</div>
        <div>{{ ic }}</div>
      </div>
      <div v-if="infoItems.length > 0 && info" class="confirm-modal_info mt15">
        <div class="d-flex" v-for="item in infoItems" :key="item.prop">
          <span>{{ item.label }}:</span>
          <span>{{ info[item.prop] || '-' }}</span>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="() => $emit('close', false)" type="info">
        {{isReviewNotes ? 'Cancel' : 'Previous'}}
      </el-button>
      <el-button type="primary" @click="confirmHandler">
        Confirm
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Confirm your update'
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    comfirmHtml: {
      type: String
    },
    username: {
      type: String,
      default: ''
    },
    isReviewNotes: {
      type: Boolean,
      default: false
    },
    ic: {
      type: String,
      default: ''
    },
    infoItems: {
      type: Array,
      default: () => []
    },
    info: {
      type: Object
    }
  },
  data () {
    return {
      isReviewed: true
    }
  },
  methods: {
    confirmHandler () {
      if (this.isReviewNotes) {
        if (!this.isReviewed) {
          this.$message.error('Please reviewed')
        } else {
          this.$emit('confirm', true)
        }
        return
      }
      this.$emit('confirm', true)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog__header {
    padding-bottom: 20px;
    border-bottom: $border-style;
  }
}
.confirm-modal_info {
  color: $text-color-secondarygray;
  line-height: 20px;
}
.confirm-modal_user {
  color: #000000;
}
</style>
<template>
  <div class="noheader-table mt25">
    <div v-if="subtitle" class="textlightgray12 mb10">{{ subtitle }}</div>
    <el-table
      :show-header="hasHeader"
      :border="hasBorder"
      fit
      size="small"
      :data="tableData"
    >
      <el-table-column
        v-for="(item, index) in tableItems"
        :key="item.prop + index"
        :label="item.label"
        :prop="item.prop"
        :width="item.width || ''"
      >
        <template slot-scope="scope">
          <div v-if="item.isComputed" >
            {{ computedInfo(item.prop, scope.row) }}
          </div>
          <div v-else-if="item.isObject" >
            {{ scope.row[item.prop] && scope.row[item.prop].name }}
          </div>
          <div v-else :class="item.classname">
            {{ scope.row[item.prop] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="actions.length > 0" label="Actions" fixed="right">
        <template slot-scope="scope">
          <div class="d-flex">
            <template v-for="item in actions">
              <div
                v-if="item.icon"
                class="noheader-table_i d-flex"
                :key="item.type"
                @click="() => actionChangeHandler(item, scope.row)"
              >
                <i :class="item.icon"></i>
              </div>
              <el-button v-else-if="item.label" :key="item.label">{{ item.label }}</el-button>
            </template>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import utils from '@/utils/Utils'
import { postTcuAction } from '@/services'
export default {
  props: {
    subtitle: {
      type: String,
      default: ''
    },
    getTableDataFunc: {
      type: Function
    },
    tableData: {
      type: Array,
      default: () => []
    },
    tableItems: {
      type: Array,
      default: () => []
    },
    hasBorder: {
      type: Boolean,
      default: true
    },
    hasHeader: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  },
  methods: {
    computedInfo (str, data) {
      const list = str.split('-')
      if (data[list[0]] && utils.isPlainObject(data[list[0]])) return data[list[0]][data[list[1]]]
      return (data[list[0]] && data[list[1]]) ? (data[list[0]] + ' -> ' + data[list[1]]) : (data[list[0]] || data[list[1]])
    },
    async actionChangeHandler (action, data) {
      if (action.type == 'jump') {
        this.$router.push(action.url + '&tid=' + data.id + '&id=' + this.$route.query.id)
      } else if (action.type == 'request') {
        try {
          const res = await utils.showConfirmDialog(this, 'Confirmation', 'Are you sure you want to delete?')
          if (res == 'confirm') {
            const requestdata = await postTcuAction(action.action, action.url + '/' + data.id)
            if (requestdata.code == 200) {
              this.$message.success('Operate Success!')
              utils.requestSuccessCb(this, 'Operate Success!', 200, () => {
                this.$emit('refresh')
              })
            }
          }
        } catch (error) {
          
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-table th.el-table__cell {
    background: #F2F3F5;
  }
}
.noheader-table_i {
  width: 18px;
  height: 18px;
  margin-right: 7px;
  justify-content: center;
  i {
    font-size: 16px;
    color: $text-color-darkblue;
    cursor: pointer;
  }
}
</style>